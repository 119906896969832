import { render, staticRenderFns } from "./SectionGalleryV2.vue?vue&type=template&id=8a221a62&"
import script from "./SectionGalleryV2.vue?vue&type=script&lang=js&"
export * from "./SectionGalleryV2.vue?vue&type=script&lang=js&"
import style0 from "./SectionGalleryV2.vue?vue&type=style&index=0&id=8a221a62&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,OrganismsCommonOrganismImageSliderV2: require('/app/components/organisms/common/OrganismImageSliderV2.vue').default})
